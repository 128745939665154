// Scripts for the video promotions component
import Player from '@vimeo/player';

/**
 * Set up each video promotion item
 */
const videoPromotions = document.getElementsByClassName('video-promotion');
if( typeof videoPromotions !== 'undefined' && videoPromotions !== null ) {
  for( let videoPromotion of videoPromotions ) {

    // Identify the dialog box for the component
    const dialog = videoPromotion.querySelector('.video-promotion__dialog');

    // Identify the video iframe
    const videoIframe = videoPromotion.querySelector('.video-promotion__media');

    // Identify the slides
    const slides = videoPromotion.getElementsByClassName('video-promotion__item');
    for( let slide of slides ) {
      /**
       * When the slide is clicked, pass the video ID to the dialog box and
       * open it
       */
      slide.addEventListener('click', function() {
        // Set up the video player
        let videoId = slide.dataset.videoId;
        videoIframe.src = 'https://player.vimeo.com/video/' + videoId;
        const player = new Player(videoIframe);

        // Open the dialog
        dialog.showModal();

        /**
         * When the dialog is closed or the escape button is pressed, pause
         * the video
         */
        let dialogClose = dialog.querySelector('.video-promotion__close');
        dialogClose.addEventListener('click', function() {
          closeDialog();
        });
        dialog.addEventListener('close', function() {
          closeDialog();
        });

        function closeDialog() {
          // Stop the video and destroy the player
          if( player !== null )
            player.pause().catch(function(error) {
              switch (error.name) {
                case 'PasswordError':
                  // the video is password-protected and the viewer needs to enter the
                  // password first
                  break;

                case 'PrivacyError':
                  // the video is private
                  break;

                default:
                  // some other error occurred
                  break;
              }
            });

          // Close the dialog
          dialog.close();
        }
      });
    }
  }
}
